<template>
  <div :class="this.$route.params.id ? 'pa-12' : ''">
    <v-card :loading="loading" class="mx-auto" :width="isMobile ? '100%' : 800">
      <iframe
        v-if="localPost.category == '비디오 컨텐츠'"
        width="100%"
        :height="isMobile ? 200 : 500"
        :src="`https://www.youtube.com/embed/${localPost.youtubeUrl}`"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      ></iframe>

      <v-img
        v-else
        :lazy-src="
          `https://drive.google.com/uc?export=view&id=${localPost.imageUrl}`
        "
        :height="isMobile ? '50%' : '100%'"
        :src="
          `https://drive.google.com/uc?export=view&id=${localPost.imageUrl}`
        "
      ></v-img>

      <v-card-text>
        <div class="d-flex justify-space-between mt-4">
          <div class="grey--text">{{ localPost.numViews }} Views</div>
          <v-btn color="#9575cd" outlined label @click="copyLink()">
            <b v-if="!isMobile">Copy post link</b>
            <v-icon v-bind="attrs" v-on="on">mdi-share-variant-outline</v-icon>
          </v-btn>
          <v-snackbar v-model="snackbar" timeout="2000" color="#9575cd">
            The post link has been copied to the clipboard.

            <template v-slot:action="{ attrs }">
              <v-btn
                color="white"
                text
                v-bind="attrs"
                @click="snackbar = false"
              >
                Close
              </v-btn>
            </template>
          </v-snackbar>
        </div>
      </v-card-text>

      <v-divider class="mx-2"></v-divider>

      <!-- <v-card-text> -->
      <v-card-title>{{ localPost.title }} </v-card-title>
      <!-- </v-card-text> -->

      <v-card-text>
        <!-- <div class="mt-1" v-if="localPost.content.length > 200">
          {{ localPost.content.slice(0, 200) }}
          <a>
            Read more...
          </a>
        </div> -->
        <div class="mt-1" v-html="localPost.content"></div>
      </v-card-text>
      <v-card-text>
        <div class="grey--text">
          {{ localPost.fromNow }}
        </div>
      </v-card-text>

      <v-card-text>
        <v-divider v-if="this.$route.params.id" class="mx-2 mb-5"></v-divider>
        <div
          :class="
            !!this.$route.params.id
              ? 'd-flex justify-space-between align-center'
              : 'd-flex justify-end align-center'
          "
        >
          <v-btn
            v-if="this.$route.params.id"
            depressed
            color="#9575cd"
            dark
            onclick="history.back()"
            >Go Back</v-btn
          >

          <v-menu top :offset-x="true" v-if="isLoggedIn">
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on">
                <v-icon color="black">mdi-dots-vertical</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item
                :to="{
                  path: `/project/videos/edit/${localPost._id}`,
                }"
              >
                <v-list-item-title>
                  <v-icon left> mdi-pencil </v-icon>수정하기</v-list-item-title
                >
              </v-list-item>
              <v-list-item @click="dialog = true">
                <v-list-item-title
                  ><v-icon left> mdi-trash-can-outline </v-icon
                  >삭제하기</v-list-item-title
                >
              </v-list-item>
            </v-list>
          </v-menu>
        </div></v-card-text
      >
    </v-card>

    <v-dialog v-model="dialog" persistent max-width="290">
      <v-card>
        <v-card-title class="text-h6">
          게시물을 삭제합니다.
        </v-card-title>
        <v-card-text
          >게시물을 영구적으로 삭제합니다. <br />
          게시물에 중요한 내용이 있다면 반드시 확인하세요.</v-card-text
        >

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="dialog = false">
            취소
          </v-btn>
          <v-btn
            color="red darken-1"
            text
            @click="deleteVideo(localPost._id, localPost.category)"
          >
            삭제하기
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from 'axios';
import { mapGetters, mapActions } from 'vuex';

import moment from 'moment';
import copy from 'clipboard-copy';

export default {
  computed: {
    ...mapGetters(['isLoggedIn']),
    isMobile() {
      return this.windowWidth < 500;
    },
  },
  mounted() {
    window.addEventListener('resize', () => {
      this.windowWidth = window.innerWidth;
    });
  },
  name: 'PostDetail',
  props: ['post'],
  data: () => ({
    readMoreActivated: false,
    localPost: '',
    loading: false,
    snackbar: false,
    selection: 1,
    linkUrl: '',
    dialog: false,
    offset: true,
    windowWidth: window.innerWidth,
  }),
  created() {
    if (!this.post) {
      this.getPosts();
    } else {
      this.localPost = this.post;
      this.linkUrl = `${window.location.href}/${this.post._id}`;
    }

    if (this.localPost.content > 200) {
      this.readMoreActivated = true;
    } else {
      this.readMoreActivated = false;
    }

    if (this.$route.params.id) {
      this.linkUrl = `${window.location.href}`;
    }
  },
  methods: {
    ...mapActions(['deletePost', 'getVideos']),

    //TODO:HENDRIK:Need to refactoring code increase view count inner PostDetail component
    getPosts() {
      axios
        .get(`${this.$ROOT_API}/posts/${this.$route.params.id}`)
        .then((res) => {
          this.localPost = res.data;
          this.localPost.fromNow = moment(res.data.createdAt).fromNow();
        });
    },
    copyLink() {
      copy(this.linkUrl);
      this.snackbar = true;
    },
    deleteVideo(id, category) {
      this.deletePost(id, category);
    },
  },
};
</script>

<style lang="scss" scoped>
span {
  color: #fff !important;
}
</style>
