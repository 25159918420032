var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:this.$route.params.id ? 'pa-12' : ''},[_c('v-card',{staticClass:"mx-auto",attrs:{"loading":_vm.loading,"width":_vm.isMobile ? '100%' : 800}},[(_vm.localPost.category == '비디오 컨텐츠')?_c('iframe',{attrs:{"width":"100%","height":_vm.isMobile ? 200 : 500,"src":("https://www.youtube.com/embed/" + (_vm.localPost.youtubeUrl)),"title":"YouTube video player","frameborder":"0","allow":"accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture","allowfullscreen":""}}):_c('v-img',{attrs:{"lazy-src":("https://drive.google.com/uc?export=view&id=" + (_vm.localPost.imageUrl)),"height":_vm.isMobile ? '50%' : '100%',"src":("https://drive.google.com/uc?export=view&id=" + (_vm.localPost.imageUrl))}}),_c('v-card-text',[_c('div',{staticClass:"d-flex justify-space-between mt-4"},[_c('div',{staticClass:"grey--text"},[_vm._v(_vm._s(_vm.localPost.numViews)+" Views")]),_c('v-btn',{attrs:{"color":"#9575cd","outlined":"","label":""},on:{"click":function($event){return _vm.copyLink()}}},[(!_vm.isMobile)?_c('b',[_vm._v("Copy post link")]):_vm._e(),_c('v-icon',_vm._g(_vm._b({},'v-icon',_vm.attrs,false),_vm.on),[_vm._v("mdi-share-variant-outline")])],1),_c('v-snackbar',{attrs:{"timeout":"2000","color":"#9575cd"},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"color":"white","text":""},on:{"click":function($event){_vm.snackbar = false}}},'v-btn',attrs,false),[_vm._v(" Close ")])]}}]),model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" The post link has been copied to the clipboard. ")])],1)]),_c('v-divider',{staticClass:"mx-2"}),_c('v-card-title',[_vm._v(_vm._s(_vm.localPost.title)+" ")]),_c('v-card-text',[_c('div',{staticClass:"mt-1",domProps:{"innerHTML":_vm._s(_vm.localPost.content)}})]),_c('v-card-text',[_c('div',{staticClass:"grey--text"},[_vm._v(" "+_vm._s(_vm.localPost.fromNow)+" ")])]),_c('v-card-text',[(this.$route.params.id)?_c('v-divider',{staticClass:"mx-2 mb-5"}):_vm._e(),_c('div',{class:!!this.$route.params.id
            ? 'd-flex justify-space-between align-center'
            : 'd-flex justify-end align-center'},[(this.$route.params.id)?_c('v-btn',{attrs:{"depressed":"","color":"#9575cd","dark":"","onclick":"history.back()"}},[_vm._v("Go Back")]):_vm._e(),(_vm.isLoggedIn)?_c('v-menu',{attrs:{"top":"","offset-x":true},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"black"}},[_vm._v("mdi-dots-vertical")])],1)]}}],null,false,1759850144)},[_c('v-list',[_c('v-list-item',{attrs:{"to":{
                path: ("/project/videos/edit/" + (_vm.localPost._id)),
              }}},[_c('v-list-item-title',[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-pencil ")]),_vm._v("수정하기")],1)],1),_c('v-list-item',{on:{"click":function($event){_vm.dialog = true}}},[_c('v-list-item-title',[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-trash-can-outline ")]),_vm._v("삭제하기")],1)],1)],1)],1):_vm._e()],1)],1)],1),_c('v-dialog',{attrs:{"persistent":"","max-width":"290"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h6"},[_vm._v(" 게시물을 삭제합니다. ")]),_c('v-card-text',[_vm._v("게시물을 영구적으로 삭제합니다. "),_c('br'),_vm._v(" 게시물에 중요한 내용이 있다면 반드시 확인하세요.")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"green darken-1","text":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" 취소 ")]),_c('v-btn',{attrs:{"color":"red darken-1","text":""},on:{"click":function($event){return _vm.deleteVideo(_vm.localPost._id, _vm.localPost.category)}}},[_vm._v(" 삭제하기 ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }